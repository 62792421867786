import React from 'react'
import Reset from './reset'
import Global from './global'
import { Helmet } from 'react-helmet'
import Header from '../Header'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './material-ui-custom-theme'
import Footer from '../Footer'
import favicon from '../../../../static/favicon.ico'
import queryString from 'query-string-es5'
import { setCookie } from '../../../../src/utils/cookies'
import config from '../../../site.config'

const descriptionDefault = 'Trouvez votre avocat au Québec.'
const titleDefault = 'Trouvez vos Avocats & Services Légaux au Québec'

const Layout = ({
    children,
    lowFootprint,
    title = config.siteMetadata.title,
    description = config.siteMetadata.description,
    location,
    image,
    noPhones,
    noindex = false,
    canonical,
    noFooter,
}) => {
    if (typeof window !== 'undefined') {
        if (document.referrer !== '') {
            setCookie('referrer', document.referrer)
        }
        const { pub, keyword, device } = queryString.parse(location.search)
        if (pub) {
            setCookie('campaignID', pub)
        }
        if (device) {
            setCookie('device', device)
        }
        if (keyword) {
            setCookie('keyword', keyword)
        }
        // pub={campaignid}&keyword={keyword}&device={device}
    }
    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <meta charset="utf-8" />
                <meta http-equiv="x-ua-compatible" content="ie=edge"></meta>
                <meta
                    name="viewport"
                    content="user-scalable=no,initial-scale=1.0,maximum-scale=1.0"
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossorigin
                />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
                <link rel="shortcut icon" href={favicon} />
                <link
                    rel="canonical"
                    href={`${config.siteMetadata.siteUrl}${location.pathname}`}></link>
                <title>{title}</title>
                {noindex === true ? (
                    <meta name="robots" content="noindex" />
                ) : null}
                <meta name="description" content={description} />
                <meta property="og:type" content="website"></meta>
                <meta
                    property="og:site_name"
                    content={config.siteMetadata.siteName}
                />
                <meta property="og:description" content={description} />
                <meta property="og:title" content={title} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:locale" content="fr_CA" />
                <meta property="og:image" content={image} />
            </Helmet>
            <Header noPhones={noPhones} />
            {children}
            {noFooter ? null : (
                <Footer sitename={config.siteMetadata.siteName} />
            )}
            <Reset />
            <Global />
        </ThemeProvider>
    )
}

export default Layout
