export const primary = '#4527a0' //'#4e3765'
export const lightPrimary = '#7953d2' //'#5b2bea'
export const darkPrimary = '#000070' //'#3e2a53'
export const secondary = '#ff7043' //'#ed7552'
export const lightSecondary = '#ffa270'
export const darkSecondary = '#c63f17'

export const gradient = primary //`linear-gradient(-45deg,#376365,#5b2bea)` //`linear-gradient(-45deg, ${primary}, ${lightPrimary})`

export const black = '#16161d'
export const lightblack = '#7c7c98'
export const lightgrey = '#f7f7f7'
export const red = '#e34c4c'

export const h1 = '3.375rem'
export const h2 = '2.25rem'
export const h3 = '1.5rem'
export const h4 = '1.1rem'
export const h5 = '0.667rem'
export const h6 = '0.444rem'
export const h7 = '0.296rem'
