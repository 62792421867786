module.exports = {
    siteMetadata: {
        siteUrl: 'https://avocatsalcoolauvolant.ca',
        siteName: 'AvocatsAlcoolAuVolant',
        title: 'Besoins d’un avocat spécialisé pour alcool au volant ?',
        description:
            'Trouvez le bon avocat pour votre arrestation pour conduite avec facultés affaiblies et augmenter vos chances de gagner votre dossier!',

        gtag: 'G-YBQF2RF8MZ',
        gads: 'AW-624237663',
        ga: '',
        splashImgName: 'splash.jpg',
    },
    defaultFormConfig: {
        lawType: 'crim-transport',
        description: 'Conduite avec facultés affaiblies',
        hideLawType: true,
        hideOthersInvolved: true,
        hideDescription: true,
        source: 'avocatsalcoolauvolant',
        othersInvolved: 'DPCP',
        forceEmail: true,
        mobileBackground: true,
        simple: true,
    },
    content: {
        find: ' Trouvez le bon avocat pour votre arrestation pour conduite avec facultés affaiblies et augmenter vos chances de gagner votre dossier!',
        answer: 'Répondez à quelques questions seulement et JuriGo.ca identifiera le meilleur avocat pour votre dossierde conduite avec facultés affaiblies',
        lawyerFor: 'facultées affaiblies',
    },
}
