import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        font-family: 'Roboto', sans-serif;
        box-sizing: border-box;
    }
    strong {
        font-weight: 600;
    }
    a {
        text-decoration: none;
    }
    a:visited {
        color: currentColor;
    }
    body {
        overflow: auto !important;
        overflow-x: hidden;
        padding-right: 0 !important;
    }
    h1, h2, h3, h4, h5, h6 {
        padding: 15px 0;
    }
    h1 {
        font-size: 27px;
        line-height: 33px;
        padding: 1rem 0 1rem 0;
        text-align: center;
        strong {
            font-weight: 600;
        }
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 21px

    }

    main {
        margin: 0 auto;
        max-width: 960px;
        padding: 0 12px;
    }
    p {
        padding: 0.5rem 0;
        line-height: 1.5;
    }
    .gatsby-image-wrapper {
        width: 100%;
        z-index: -1;
    }
    @media (max-width: 667px) {
        h1 {
            padding: 0;
        }
    }
    
`

export default GlobalStyle
